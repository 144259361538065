import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Hi 👋🏽, Welcome to <code>KidusMT</code>.
        </p>
        <a
          className="App-link"
          href="https://github.com/KidusMT"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>
      </header>
    </div>
  );
}

export default App;
